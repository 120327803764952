<template>
  <div class="service">
    <!-- <div style="height: 25px; background: #fff"></div> -->
    <!-- <div class="head">
          在线客服
        <img
            class="left"
            @click="$router.back(-1)"
            src="../../../assets/ShopImg/left.png"
            alt=""
        />
        </div> -->
    <!-- <div v-html="service.OnlineService"></div> -->
  </div>
</template>
<script>
import { queryOnlineService } from "@/api/shop";
export default {
  data() {
    return {
      service: {},
    };
  },
  created() {},
  mounted() {
    // this.init();
  },
  methods: {
    async init() {
      const res = await queryOnlineService();
      this.service = res.response;
    },
  },
};
</script>
<style lang="scss" scoped>
.service {
  min-height: 100vh;
  background-color: #fff;
  padding: 15px;
  /deep/img {
    width: 100%;
  }
}
.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  background: #fff;

  .left {
    width: 17px;
    height: 17px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
